<template>
    <div class="solutionDetails_page">
        <div class="content">
            <Breadcrumb :breadcrumbList="breadcrumbList"></Breadcrumb>
            <div class="main">
                <div class="title_div display">{{ title }}</div>
                <div class="solutionDetail">
                    <div class="describe_cont">
                        <div class="describe_details">
                            <div class="title">系统概述</div>
                            <div class="details">
                                {{ formData.briefIntroduce }}
                            </div>
                        </div>
                    </div>
                    <div class="describe_cont">
                        <div class="describe_details">
                            <div class="title" style="margin-bottom: 14px">系统特点</div>
                            <div class="rich_text" v-html="formData.content"></div>
                        </div>
                    </div>

                    <div class="describe_cont">
                        <div class="describe_details">
                            <div class="title">系统结构组成</div>
                            <div class="goods_div flex">
                                <img class="describe_img" :src="formData.icon" alt="" />
                                <div class="goodsClass flex column">
                                    <div class="list flex pointer"
                                        v-for="(item, index) in formData.solutionSystemStructureVOS" :key="index"
                                        @click="changeBtn(item.goodsVOS, index)">
                                        <div class="goodsIndex display">{{ index + 1 }}</div>
                                        <div class="goodsClassificationName"
                                            :class="activeIndex == index ? 'active' : ''">
                                            {{ item.systemStructureName }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="el_carouse_box" v-if="goodsList.length != 0">
                    <div class="title">推荐材料</div>
                    <el-carousel :autoplay="false" arrow="always">
                        <el-carousel-item class="el-car-item" v-for="(list, index) in goodsList" :key="index">
                            <div class="goodsList flex-center column" v-for="(imgList, index2) in list" :key="index2"
                                @click="toDetails(imgList)">
                                <img class="icon_img" :src="imgList.coverUrl" :alt="imgList.productName" />
                                <div class="name display">
                                    <span row>{{ imgList.productName }}</span>
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/breadcrumb'
export default {
    name: 'solutionDetails',
    components: {
        Breadcrumb,
    },
    data() {
        return {
            title: '',
            activeIndex: 0,
            formData: {},
            breadcrumbList: [
                {
                    name: '解决方案',
                    url: '',
                },
            ],
            goodsList: [],
        }
    },
    created() {
        this.getList()
        this.breadcrumbList = [
            {
                name: '解决方案',
                url: '',
            },
            {
                name: this.$route.query.fatherName,
                url: '',
            },
            {
                name: this.$route.query.name,
                url: '',
            },
        ]
        this.title = this.$route.query.name
    },
    methods: {
        getList() {
            let params = {
                threeSolutionId: this.$route.query.id,
            }
            this.api.findSolutionDetails(params).then((res) => {
                this.formData = res.data
                if (res.data.solutionSystemStructureVOS[0].goodsVOS != null) {
                    this.goodsList = this.init(
                        res.data.solutionSystemStructureVOS[0].goodsVOS,
                        4
                    )
                } else {
                    for (const [
                        index,
                        item,
                    ] of res.data.solutionSystemStructureVOS.entries()) {
                        if (item.goodsVOS != null) {
                            this.activeIndex = index
                            // let arr = []
                            // item.goodsVOS.forEach((datas) => {
                            // 	if (datas != null) {
                            // 		arr.push(datas)
                            // 	}
                            // })
                            this.goodsList = this.init(item.goodsVOS, 4)
                            return
                        }
                    }
                }
            })
        },
        //轮播图初始化
        init(dataList, num) {
            let newDataList = []
            let current = 0
            if (dataList && dataList.length > 0) {
                for (let i = 0; i <= dataList.length - 1; i++) {
                    if (i % num !== 0 || i === 0) {
                        if (!newDataList[current]) {
                            newDataList.push([dataList[i]])
                        } else {
                            newDataList[current].push(dataList[i])
                        }
                    } else {
                        current++
                        newDataList.push([dataList[i]])
                    }
                }
            }
            return (dataList = [...newDataList])
        },
        toDetails(item) {
            this.$router.push({
                path: '/goodsDetails',
                query: {
                    goodsId: item.id,
                    goodsName: item.productName,
                    fatherName: this.$route.query.name,
                },
            })
        },
        changeBtn(item, index) {
            this.activeIndex = index
            this.goodsList = this.init(item, 4)
        },
    },
}
</script>
<style lang="scss" scoped>
.solutionDetails_page {
    .main {
        width: 100%;
        border: 1px solid rgba(222, 222, 222, 0.96);
        padding: 60px 0;
        margin-bottom: 72px;

        .title_div {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            line-height: 24px;
        }

        .solutionDetail {
            width: 100%;
            padding-left: 22px;
            box-sizing: border-box;

            .describe_cont {
                .describe_img {
                    width: 940px;
                    height: 456px;
                }

                .describe_details {
                    .title {
                        font-size: 14pt;
                        color: #000;
                        line-height: 24px;
                        margin-top: 60px;
                        font-weight: bold;
                    }

                    .details {
                        width: 1150px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        line-height: 24px;
                        margin-top: 10px;
                    }
                }
            }

            .rich_text {
                width: 100%;
                padding-right: 44px;
                box-sizing: border-box;
            }
        }

        .el_carouse_box {
            margin-top: 10px;

            .title {
                font-size: 14pt;
                color: #000;
                line-height: 24px;
                margin-top: 60px;
                font-weight: bold;
                padding-left: 22px;
            }
        }

        ::v-deep.el-car-item {
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 96px;
        }

        .goodsList {
            width: 238px;
            height: 287px;
            background: white;
            border: 1px solid rgba(222, 222, 222, 0.96);
            margin-right: 19px;
            box-sizing: border-box;
            cursor: pointer;

            .icon_img {
                width: 236px;
                height: 238px;
            }

            .name {
                width: 100%;
                height: 49px;
                font-size: 14px;
                color: #333333;
                line-height: 24px;
                background: #dedede;
                padding: 0px 20px;
                box-sizing: border-box;
            }
        }

        .goodsList:last-child {
            margin-right: 0px;
        }

        .goodsList:hover {
            border: 1px solid #e62129 !important;

            .name {
                background: #e62129 !important;
                color: #fff !important;
            }
        }

        ::v-deep.el-carousel {
            .el-carousel__container {
                height: 370px;

                .el-carousel__arrow {
                    width: 54px;
                    height: 54px;
                    font-size: 24px;
                    background: none;
                    border: 1px solid #e62129;
                    color: #e62129 !important;
                    border-radius: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        ::v-deep.el-carousel__indicators {
            display: none;
        }
    }

    .goods_div {
        margin-top: 22px;

        .goodsClass {
            margin-left: 22px;

            .list {
                .goodsIndex {
                    min-width: 22px;
                    height: 16px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    background: #e23030;
                    margin-top: 4px;
                    margin-right: 8px;
                }

                .goodsClassificationName {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                }

                .active {
                    color: #e33636 !important;
                }
            }
        }
    }
}
</style>